import Page from '@jumbo/shared/Page'

import { Home } from 'app/pages/home/Home'
import { Liquidity } from 'app/pages/liquidity/Liquidity'
import { Harvest } from 'app/pages/harvest/Harvest'
import { Vote } from 'app/pages/vote/Vote'
import { Mint } from 'app/pages/mint/Mint'
import { Convert } from 'app/pages/convert/Convert'
import { Upgrade } from 'app/pages/upgrade/Upgrade'
import { Crowdfunding } from 'app/pages/Crowdfunding/Crowdfunding'
import { Referral } from 'app/pages/referral/Referral'
// import Login2 from 'app/pages/auth-pages/login2'
// import { MagicLinkLogin } from 'app/pages/auth-pages/MagicLinkLogin'
import { INUKO_ADDRESS, MANGA_ADDRESS, bridges, MANGA_LEARN_URL, INUKO_LEARN_URL, PANCAKESWAP_BASE_URL } from 'app/utils/constants/contract'



/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  // {
  //   path: '/magic-link',
  //   element: <Page component={MagicLinkLogin} />
  // }
  {
    path: '/',
    element: <Page component={Home} />
  },
  {
    path: '/liquidity',
    element: <Page component={Liquidity} />
  },
  {
    path: '/harvest',
    element: <Page component={Harvest} />
  },
  {
    path: '/vote',
    element: <Page component={Vote} />
  },
  {
    path: '/mint/manga',
    element: (
      <Page component={Mint} bridgeAddress={bridges[MANGA_ADDRESS]} tokenAddress={MANGA_ADDRESS} tokenName='$MANGA' learnURL={MANGA_LEARN_URL} pancakeswapURL={PANCAKESWAP_BASE_URL} />
    )
  },
  {
    path: '/mint/inuko',
    element: (
      <Page component={Mint} bridgeAddress={bridges[INUKO_ADDRESS]} tokenAddress={INUKO_ADDRESS} tokenName='INUKO' learnURL={INUKO_LEARN_URL} pancakeswapURL={PANCAKESWAP_BASE_URL}/>
    )
  },
  {
    path: '/crowdfunding',
    element: (
      <Page component={Crowdfunding} />
    )
  },
  {
    path: '/convert',
    element: <Page component={Convert} />
  },
  {
    path: '/upgrade',
    element: <Page component={Upgrade} />
  },
  {
    path: '/referral',
    element: <Page component={Referral} />
  }
]

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  // {
  //   path: '/',
  //   element: <Page component={Home} />
  // },
  // {
  //   path: '/liquidity',
  //   element: <Page component={Liquidity} />
  // },
  // {
  //   path: '/harvest',
  //   element: <Page component={Harvest} />
  // },
  // {
  //   path: '/vote',
  //   element: <Page component={Vote} />
  // },
  // {
  //   path: '/mint/manga',
  //   element: (
  //     <Page component={Mint} bridgeAddress={bridges[MANGA_ADDRESS]} tokenAddress={MANGA_ADDRESS} tokenName='$MANGA' />
  //   )
  // },
  // {
  //   path: '/mint/inuko',
  //   element: (
  //     <Page component={Mint} bridgeAddress={bridges[INUKO_ADDRESS]} tokenAddress={INUKO_ADDRESS} tokenName='INUKO' />
  //   )
  // },
  // {
  //   path: '/convert',
  //   element: <Page component={Convert} />
  // },
  // {
  //   path: '/referral',
  //   element: <Page component={Referral} />
  // }
]

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  // {
  //   path: '/user/login',
  //   element: <Page component={Login2} layout={'solo-page'} disableSmLogin={true} />
  // }
]

const routes = [...routesForPublic, ...routesForAuthenticatedOnly, ...routesForNotAuthenticatedOnly]

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly }
