import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import GraphicEqIcon from '@mui/icons-material/GraphicEq'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined'
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'
import ListIcon from '@mui/icons-material/List'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import Groups2Icon from '@mui/icons-material/Groups2';
import GroupsIcon from '@mui/icons-material/Groups'

const menus = [
  {
    label: 'sidebar.menu.home',
    type: 'section',
    children: [
      {
        uri: '/',
        label: 'Dashboard',
        type: 'nav-item',
        icon: <GraphicEqIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/liquidity',
        label: 'Liquidity',
        type: 'nav-item',
        icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/harvest',
        label: 'Harvest',
        type: 'nav-item',
        icon: <ListIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/vote',
        label: 'Vote',
        type: 'nav-item',
        icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/mint/manga',
        label: '$MANGA → xHONO',
        type: 'nav-item',
        icon: <WidgetsOutlinedIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/mint/inuko',
        label: 'INUKO → xHONO',
        type: 'nav-item',
        icon: <WidgetsOutlinedIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/crowdfunding',
        label: 'Crowdfunding',
        type: 'nav-item',
        icon: <Groups2Icon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/convert',
        label: 'xHONO → HONOv2',
        type: 'nav-item',
        icon: <LeaderboardOutlinedIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/upgrade',
        label: 'HONOv1 → HONOv2',
        type: 'nav-item',
        icon: <LeaderboardOutlinedIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: 'https://ironball.xyz/keys',
        label: 'Mint IRONBALL Keys',
        type: 'nav-item',
        icon: <LeaderboardOutlinedIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/referral',
        label: 'Referral',
        type: 'nav-item',
        icon: <GroupsIcon sx={{ fontSize: 20 }} />
      }
    ]
  }
]

export default menus
