import { Card, CardMedia, CardActionArea } from '@mui/material';
import { getAssetPath } from '../../../utils/appHelpers';
import { ASSET_IMAGES } from '../../../utils/constants/paths';

export const IRONBALLPromotion = () => {
    return (
        <Card sx={{ maxWidth: '100%', overflow: 'hidden', textAlign: 'center' }}>
            <CardActionArea component="a" href="https://ironball.xyz/keys">
                <CardMedia
                    sx={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'contain',
                        objectPosition: 'center',
                        '@media (max-width: 768px)': {
                            height: 'auto',
                            width: '100%',
                            margin: '0 auto',
                            display: 'block'
                        }
                    }}
                    component="img"
                    image={getAssetPath(`${ASSET_IMAGES}/ironball-banner.png`)}
                />
            </CardActionArea>
        </Card>
    );
};